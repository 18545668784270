.toast-success,
.toast-error {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #ff4b4b;
  position: relative;
  transform: rotate(45deg);
  animation-delay: 100ms;
}

.toast-success {
  background: #61d345;
}

.toast-error {
  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 3px;
    background: #fff;
    bottom: 9px;
    left: 4px;
    height: 2px;
    width: 12px;
  }

  &::after {
    transform: rotate(90deg);
  }
}

.toast-success::after {
  content: "";
  box-sizing: border-box;
  animation-delay: 200ms;
  position: absolute;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-color: #fff;
  bottom: 6px;
  left: 6px;
  height: 10px;
  width: 6px;
}
