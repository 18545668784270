$colors: rgb(149, 183, 83) 1, rgb(204, 51, 0) 2, rgb(229, 169, 26) 3, rgb(51, 102, 255) 4,
  rgb(255, 15, 0) 5, rgb(255, 102, 0) 6, rgb(255, 158, 1) 7, rgb(252, 210, 2) 8, rgb(248, 255, 1) 9;

.d-f {
  display: flex;
}

.f-w {
  flex-wrap: wrap;
}

.f-c-c {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.f-c-e {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
}

.f-c {
  display: flex !important;
  align-items: center;
}

.f-c-sb {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.f-s-sb {
  display: flex !important;
  justify-content: space-between;
  align-items: flex-start;
}

.f-col-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.grid-1-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.gp-20 {
  gap: 20px;
}

.g-5 {
  gap: 10px;
}

.g-10 {
  gap: 10px;
}

.g-20 {
  gap: 20px;
}

.b-rad-4 {
  border-radius: 4px;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.end {
  display: flex;
  justify-content: flex-end;

  text-align: end;
}

.h-100 {
  height: 100%;
}

.p-12-18 {
  padding: var(--s-12) var(--s-18);
}

.p-16-20 {
  padding: 16px 20px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.p-10 {
  padding: 10px;
}

.p-30 {
  padding: 30px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-auto {
  margin-left: auto;
}

.p-20 {
  padding: 20px;
}

.m-10 {
  margin: 10px;
}

.mb-7 {
  margin-bottom: 7px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.t-center {
  text-align: center;
}

.center {
  display: grid;
  place-items: center;

  text-align: center;
}

.x-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.f-20 {
  font-size: 20px;
}

.f-18 {
  font-size: 18px !important;
}

.f-bottom-right {
  position: fixed;
  bottom: 40px;
  right: 40px;
}

.right-bottom {
  width: 100%;
  margin-top: auto;
}

.min-h-100 {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.bt {
  border-top: 1px solid #cfd7e1;
}

.bb {
  border-bottom: 1px solid #cfd7e1;
}

@each $color, $index in $colors {
  .c-#{$index} {
    color: $color;
  }

  .bgc-#{$index} {
    background-color: $color;
  }
}

.bg-red {
  background-color: red !important;
  color: #fff;

  path {
    fill: #fff;
  }
}

.bg-danger {
  background-color: FF6D60;
  color: #fff;

  path {
    fill: #fff;
  }
}

.bg-success {
  background-color: #98d8aa;
  color: #fff;

  path {
    fill: #fff;
  }
}

.c-red {
  color: red;
}

.c-black {
  color: #000 !important;
}

.icon-red {
  path {
    fill: rgb(225, 0, 0);
  }
}

.c-green {
  color: rgb(18, 210, 18);
}

.bg-green {
  background-color: rgb(18, 210, 18) !important;
  color: #fff;

  path {
    fill: #fff;
  }
}

.bg-dark {
  background-color: #434242;
  color: #fff;
}

.w-100 {
  width: 100%;
}

.txt-col1 {
  display: table-caption;
}

.txt-nowrap {
  white-space: nowrap;
}

.txt-strong {
  font-weight: 600;
}

.hover-row:hover {
  background-color: #98d8aa5f !important;
}

.f-22 {
  font-size: 22px;
}

.cp {
  cursor: pointer;
}

.f-m {
  font-weight: 500;
}

th.bg-red,
th.bg-danger,
th.bg-success,
th.bg-green {
  background-color: transparent;
  color: inherit;
}
