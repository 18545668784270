.layout {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  overflow: hidden;
}

.page-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  position: relative;
  height: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    display: block;
    padding-bottom: 70px;
  }
}

.page-content {
  overflow: hidden;
  height: 100%;
  padding: 20px;
}

.extra-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;

  @media (max-width: 780px) {
    grid-template-columns: 1fr;

    &>div:last-child {
      grid-row: 1;
    }
  }
}