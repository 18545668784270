.modal {
  &-confirm {
    min-width: 320px;
    padding: 20px;

    .btn-group {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  
    .btn-cencel {
      padding: 0;
    }

    .btn-group{
      margin-top: 20px;
    }
  }

}
