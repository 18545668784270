.mobile-menu {
    position: fixed;
    z-index: 500;

    .white-icon {
        opacity: .5;

        path {
            fill: #fff;
        }
    }

    &__overlay {
        display: none;
        position: fixed;
        inset: 0;
    }

    &.menu-open {
        .mobile-menu__overlay {
            display: block;
        }
    }

    &__wrapper {
        width: 300px;
        height: 100%;
        background-color: var(--bs-body-color);
        position: fixed;
        top: 0;
        right: 0;
        z-index: 500;
        transform: translateX(100%);
        transition: transform .25s ease-in-out;
        border-left: 1px solid hsla(0, 0%, 100%, .1);
    }

    &.menu-open .mobile-menu__wrapper {
        transform: translateX(0);
        box-shadow: -10px 0 10px rgb(0 0 0 / 20%);
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 25px;

        svg {
            min-width: 20px;
            width: 20px;
            margin-right: -20px;
        }

        &-title {
            font-size: 16px;
            font-weight: 400;
            color: #fff;
        }

        &-text {
            color: hsla(0, 0%, 100%, .7);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            width: 100%;

            path {
                fill: hsla(0, 0%, 100%, .7);
            }
        }
    }


    &__items {
        svg {
            width: 24px;
            height: 24px;
            filter: grayscale(100%);
        }
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 7px 25px;

        &-label {
            color: hsla(0, 0%, 100%, .7);
        }

        &:hover {
            background-color: #6a6cf7;

            svg {
                filter: grayscale(0) !important;
                opacity: 1;
            }

            .mobile-menu__item-label {
                color: #fff;
            }
        }

        &.active {}
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 500;
}