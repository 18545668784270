.sidebar {
  padding: 25px 25px 25px 0;
  width: 240px;
  height: 100%;
  background-color: #e9edf3;
  transition: width 0.1s, box-shadow 0.1s ease-in-out;

  svg {
    min-width: 20px;
    width: 20px;
  }

  .logistics {
    max-width: 26px;
    width: 26px;
    height: 26px;
  }

  li {
    width: 100%;
    overflow: hidden;
  }

  &__items {
    width: 100%;
    display: grid;
    gap: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    overflow: hidden;

    width: 100%;
    gap: 15px;
    margin: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 7px 10px 7px 25px;
    transition: padding 0.1s;

    span {
      display: grid;
      place-items: center;
      min-width: 20px;
    }

    &.active {
      background-color: #fff;
      border-left: 3px solid #6a6cf7;

      path {
        fill: #333;
      }

      .sidebar__item-text {
        color: #333;
        font-weight: 500;
      }
    }

    &:hover {
      background-color: #fff;
    }

    &-text {
      color: #536061;
      white-space: nowrap;
    }

    path {
      fill: #536061;
    }
  }

  @media (max-width: 1200px) and (min-width: 769px) {
    width: 70px;

    &__item {
      gap: 20px;
    }

    li {
      width: 58px;
    }

    &:hover {
      width: 240px;

      li {
        width: 100%;
      }

      .sidebar__item {
        gap: 15px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    overflow: auto;
    height: max-content;
    padding: 13px 15px;

    &__items {
      width: max-content;
      display: flex;
      height: max-content;
    }

    li {
      width: max-content;
      height: max-content;
    }

    &__item {
      width: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      border-radius: 20px;
      padding: 7px 15px;
      width: max-content;

      &.active {
        border-left: none;
      }
    }
  }
}

.sidebar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}