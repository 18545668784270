.btn {
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-padding-y: 0.6rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.95rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.428571429;
  --bs-btn-color: #333;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.3rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  background-color: var(--bs-btn-bg);
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  color: var(--bs-btn-color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;

  &-primary {
    --bs-btn-bg: #6a6cf7;
    --bs-btn-border-color: #6a6cf7;
    --bs-btn-hover-bg: #8082f8;
    --bs-btn-hover-border-color: #797bf8;
    --bs-btn-focus-shadow-rgb: 90, 92, 210;
    --bs-btn-active-bg: #8889f9;
    --bs-btn-active-border-color: #797bf8;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-bg: #6a6cf7;
    --bs-btn-disabled-border-color: #6a6cf7;
    --bs-btn-color: #fff;
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
    --bs-btn-disabled-color: hsla(0, 0%, 100%, 0.6);
    color: #fff !important;
  }

  &-default {
    --bs-btn-color: #fff;
    --bs-btn-bg: #72809d;
    --bs-btn-border-color: #72809d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #8082f8;
    --bs-btn-hover-border-color: #797bf8;
    --bs-btn-focus-shadow-rgb: 90, 92, 210;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #8889f9;
    --bs-btn-active-border-color: #797bf8;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #f0f0f0;
    --bs-btn-disabled-bg: #72809d;
    --bs-btn-disabled-border-color: #72809d;
  }

  &-secondary {
    --bs-btn-bg: #eeeff0;
    --bs-btn-hover-bg: #e1e2e4;
    --bs-btn-active-bg: #e1e2e4;
    --bs-btn-disabled-bg: #eeeff0;
    --bs-btn-disabled-color: rgba(0, 0, 0, 0.6);
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.1);
  }

  &-third {
    background-color: #58b9d6;
    color: #fff;
  }

  &-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
    color: #fff;
  }

  &.hover {
    &:hover {
      border-color: transparent;
      background-color: #86cb43;
    }
  }

  &-danger {
    background-color: #ff3e1d;
    color: #fff !important;
    margin-left: auto;
  }

  &-dark {
    background-color: var(--bs-gray-900);
    color: #fff !important;
  }

  &-cencel {
    color: #72809d;

    span {
      text-decoration: none;
    }

    span:hover {
      text-decoration: underline;
    }
  }

  &.with-plus {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 5px;
  }

  .plus {
    width: 16px !important;
    height: 16px !important;

    path {
      fill: #fff;
    }
  }
}