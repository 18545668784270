.give-check {
  width: 100%;
  min-width: 200px;
  padding: 20px;

  &__bg {
    background-color: rgb(240, 244, 248);
  }

  .bg-blue {
    background-color: #5cc0de;
  }

  .bg-green {
    background-color: #86cb43;
    color: #333;
  }

  ul {
    margin-top: 10px;
  }

  li {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 10px;
    border-top: 1px solid #333;

    .title {
      display: flex;
      align-items: center;

      a {
        color: rgb(52, 152, 219);
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span {
        font-size: 16px;
        margin-left: 3px;
      }
    }

    p {
      font-size: 18px;
    }

    &>p {
      color: #ff4628;
    }
  }

  &__modal {
    li {
      justify-content: center;
      gap: 5px;
      font-size: 16px;
      border-top: none;
    }
  }

  .price {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 28px;
    margin: 10px 0;
    font-weight: 600;
  }

  .item-price {
    font-weight: 500;
  }

  .balance {
    font-size: 1.75rem;
    text-align: center;
    font-weight: 400;
    margin: 10px 0;
    color: #333;
  }

  .c-red {
    color: red;
  }

  .link {
    background-color: transparent;
    color: rgb(52, 152, 219);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .status-card {
    border: 2px dotted #000;

    h1 {
      margin: 0;
      columns: #000;
      font-size: 1.75rem;
    }

    &__top {
      padding: 10px 7px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding-right: 30px;
      position: relative;
    }

    .packed {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 25px !important;
      height: 25px !important;

      path {
        fill: #86cb43;
      }
    }
  }

  .status-text {
    padding: 5px;
    background-color: rgb(91, 192, 222);
    color: #fff;
    border-radius: 4px;
    padding: 3px 11px;
    font-size: 18.375px;
    margin-right: 20px;
  }

  .btn-primary {
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      width: 14px;

      path {
        fill: #fff;
      }
    }
  }

  .paid {
    display: flex;
    gap: 10px;
    width: max-content;
    background-color: #6bc48d;
    color: #fff;
    padding: 3px 7px;

    svg {
      width: 20px;
    }

    path {
      fill: #fff;
    }
  }

  .lock {
    width: 22px;
    margin-left: auto;

    path {
      fill: #ff4628;
    }

    &.--open {
      path {
        fill: #6bc48d;
      }
    }
  }

  .btn-copy {
    border-radius: 0 4px 4px 0;
  }

  @media (max-width: 780px) {
    max-width: none !important;
  }
}