.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2d3134;

  padding: 10px 15px;
  height: 70px;

  * {
    color: #fff;
  }

  &__burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    width: 20px;
    height: 18px;
    cursor: pointer;

    span {
      display: block;
      height: 2px;
      background-color: #fff;
      opacity: 0.5;
    }

    &:hover {
      span {
        opacity: 1;
      }
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 20px;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 25px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__right {
    path {
      fill: #fff;
      opacity: 0.5;
    }

    svg:hover {
      path {
        opacity: 1;
      }
    }
  }

  .icon-chart {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  &__items {
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;
    opacity: 0.5;
    filter: grayscale(100%);

    svg {
      width: 30px;
      min-width: 30px;
      height: 30px;
      margin-right: 7px;
    }

    &:hover {
      opacity: 1;
      filter: grayscale(0);
    }

    &-text {
      font-size: 16px;
      font-weight: 500;
    }

    &.active {
      opacity: 1;
      filter: grayscale(0);
    }
  }

  .life-ring {
    path {
      fill: #fff;
    }
  }

  .permission-icon {
    path {
      fill: #fff;
    }
  }

  @media (max-width: 1023px) {
    &__items {
      display: none;
    }

    &__item {
      &.active {
        display: none;
      }
    }
  }
}

.avatar {
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 100;

  img {
    width: 100%;
    height: 100%;
    border-radius: 9px;
    border: 1px solid rgba(236, 240, 241, 0.1);
    object-fit: cover;
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 10px;
    padding-bottom: 5px;
    border-radius: 0 0 4px 4px;
    background-color: rgb(45, 49, 52);

    li,
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      list-style: none;
      white-space: nowrap;
      padding: 5px 10px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 200;
      opacity: 0.7;

      path {
        opacity: 1;
      }

      &:hover {
        background-color: rgb(106, 108, 247);
      }
    }

    &.active {
      display: block;
    }
  }
}
