.badge {
    padding: 2px 8px;
    border-radius: 50px;
    font-size: 12px;

    &-new {
        background-color: var(--primary);
    }

    &-approved {
        background-color: #067803;
        color: #fff;
    }

    &-done {
        background-color: #888888;
        color: #fff;
    }

    &-accepted {
        background-color: #1CCA18;
        color: #fff;
    }

    &-rejected {
        background-color: var(--danger);
        color: #fff;
    }
}